var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`trial__wrapper ${_vm.$i18n.locale}`},[_c('div',{staticClass:"trial__heading"},[_c('Heading',{attrs:{"kind":"h3"},domProps:{"innerHTML":_vm._s(_vm.$t('signUp.first_seen'))}}),_vm._v(" "),_c('div',{staticClass:"trial__wrapper--boxes"},[_c('div',{staticClass:"trial__wrapper--item",domProps:{"innerHTML":_vm._s(_vm.$t('signUp.free_period'))}}),_vm._v(" "),_c('div',{staticClass:"trial__wrapper--item",domProps:{"innerHTML":_vm._s(
          _vm.$t('signUp.month_fee', {
            weeks: _vm.$tc('signUp.weeks', _vm.trialLength),
            amount: _vm.$t('amounts.amount'),
          })
        )}})]),_vm._v(" "),_c('Heading',{attrs:{"kind":"h2"},domProps:{"innerHTML":_vm._s(_vm.$t('signUp.title'))}})],1),_vm._v(" "),_c('p',{staticClass:"mb-0 trial__description",domProps:{"innerHTML":_vm._s(
      _vm.$t('signUp.description', {
        weeks: _vm.$tc('signUp.weeks', _vm.trialLength),
        amount: _vm.$t('amounts.amount'),
      })
    )}}),_vm._v(" "),_c('Form',{ref:"signUp",attrs:{"model":_vm.signUp,"rules":_vm.rules},nativeOn:{"submit":function($event){$event.preventDefault();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submitForm.apply(null, arguments)}}},[_c('FormGroup',{attrs:{"inline-form":"","prop":"email"}},[_c('div',{staticClass:"form-group-inner"},[_c('TextInput',{attrs:{"size":"large","placeholder":_vm.$t('signUp.emailPlaceholder'),"data-test":"get_trial_email"},model:{value:(_vm.signUp.email),callback:function ($$v) {_vm.$set(_vm.signUp, "email", $$v)},expression:"signUp.email"}})],1)]),_vm._v(" "),(_vm.errorMessage)?_c('div',{staticClass:"error-message",on:{"click":_vm.handleErrorClick}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(`${_vm.errorMessage}`))}})]):_vm._e(),_vm._v(" "),_c('Button',{attrs:{"icon":"icon icon-rocket","size":"large","type":"warning","data-test":"get_trial","new-design":"","full-width":"","data-analytics-id":"button.try"},on:{"click":_vm.submitForm}},[_vm._v("\n      "+_vm._s(_vm.$t('button.try'))+"\n    ")])],1),_vm._v(" "),_c('p',{staticClass:"mb-0 trial__terms"},[_c('small',[_vm._v("\n      "+_vm._s(_vm.$t('signUp.agree'))+"\n      "),_c('router-link',{attrs:{"to":{ name: _vm.PATH_TERMS_CONDITIONS }}},[_vm._v("\n        "+_vm._s(_vm.$t('signUp.terms'))+"\n      ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }